'use strict';

$(document).ready(function () {

    /////////////////////////////////////////
    // Toggle header menu ///////////////////
    /////////////////////////////////////////

    var bodyScrollTopPos;
    $('.button-mobile-nav').click(function () {
        if ($(window).width() < 950) {
            $('header').toggleClass('show');
            $('header ul.sub-menu').removeClass('show-child');
            $(this).find('i').toggleClass('fa-bars fa-times');

            if ($('header').hasClass('show')) {

                bodyScrollTopPos = $('body').scrollTop();

                setTimeout(function () {
                    $('nav').animate({ scrollTop: 0 }, 0);
                    $('html, body').addClass('noscroll');
                }, 300);
            } else {

                setTimeout(function () {
                    $('html, body').removeClass('noscroll');
                    $('body').delay(100).scrollTop(bodyScrollTopPos);
                }, 100);
            }
        }
    });

    // Toggle submenu.
    $('header ul li.menu-item-has-children a').click(function (e) {
        if ($(window).width() < 950) {
            var subItems = $(this).parent().find('ul.sub-menu').length;
            if (subItems >= 1) {
                e.preventDefault();

                $('nav').animate({ scrollTop: 0 }, 0);

                $(this).next('ul.sub-menu').addClass('show-child');
                $('.show-child li:first-child').not('.close-menu').before('<li class="close-menu"><a href="/"><i class="fas fa-arrow-left"></i></s></li>');
                $('li.close-menu a').on("click", function (e) {
                    e.preventDefault();
                    $(this).closest('ul').removeClass('show-child');
                });
            }
        }
    });

    // touche close menu when swipe to right
    // $('header nav').on('touchstart', function (e) {
    //     if ($(window).width() < 950) {
    //         var swipe = e.originalEvent.touches;
    //         var start = swipe[0].pageX;
    //         $(this).on('touchmove', function (e) {
    //             var contact = e.originalEvent.touches;
    //             var end = contact[0].pageX;
    //             var distance = end - start;
    //             if (distance > 120) {
    //                 $('header').removeClass('show');
    //                 $('.button-mobile-nav').find('i').removeClass('fa-times');
    //                 $('.button-mobile-nav').find('i').addClass('fa-bars');
    //             }
    //         });
    //     }
    // });


    // Cleanup after screen resize
    $(window).resize(function () {
        if ($(window).width() > 950) {
            $('header ul.sub-menu').removeClass('show-child');
            $('.close-menu').remove();
        }
    });

    /////////////////////////////////////////
    // Main caroucel - module-main-slider ///
    /////////////////////////////////////////
    if ($('.module-main-slider .owl-carousel').length >= 1) {

        $('.module-main-slider').each(function () {

            var element = this;

            $(element).find('.owl-carousel').owlCarousel({
                loop: $(element).find('.owl-carousel .item').length > 1 ? true : false,
                margin: 0,
                nav: false,
                dots: false,
                items: 1,
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                autoplayTimeout: 1500,
                autoplayHoverPause: true,
                // smartSpeed: 1450,
                navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
                responsive: {
                    0: {
                        touchDrag: false,
                        mouseDrag: false,
                        autoplayTimeout: 4500,
                        autoplay: true,
                        autoplayHoverPause: true
                    },
                    600: {
                        touchDrag: false,
                        mouseDrag: false,
                        autoplayTimeout: 4500,
                        autoplay: true,
                        autoplayHoverPause: true
                    }
                }
            });

            $(element).on('mouseover', function (e) {
                $(element).find('.owl-carousel').trigger('stop.owl.autoplay');
            });
            $(element).on('mouseleave', function (e) {
                $(element).find('.owl-carousel').trigger('play.owl.autoplay');
            });

            $(element).find('.next').click(function () {
                $(element).find('.owl-carousel').trigger('next.owl.carousel');
            });

            $(element).find('.prev').click(function () {
                $(element).find('.owl-carousel').trigger('prev.owl.carousel');
            });
        });
    }

    /////////////////////////////////////////
    // Module blokken ///////////////////////
    /////////////////////////////////////////
    if ($('.module-blokken .owl-carousel').length >= 1) {
        $('.module-blokken').each(function () {

            var element = this;
            var max = $(this).find('.module-blokken-blokken').attr('data-max_items_per_regel');

            $(element).find('.owl-carousel').owlCarousel({
                nav: false,
                dots: false,
                loop: $(element).find('.owl-carousel .item').length > 1 ? true : false,
                items: max,
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                responsive: {
                    0: {
                        items: 1,
                        margin: 0
                    },
                    600: {
                        items: max - 1,
                        margin: 27
                    },
                    1200: {
                        items: max,
                        margin: 20
                    }
                }

            });

            $(element).find('.next').click(function () {
                $(element).find('.owl-carousel').trigger('next.owl.carousel');
            });

            $(element).find('.prev').click(function () {
                $(element).find('.owl-carousel').trigger('prev.owl.carousel');
            });
        });
    }

    /////////////////////////////////////////
    // Module Vrij ///////////////////////
    /////////////////////////////////////////
    if ($('.module-vrij .owl-carousel').length >= 1) {
        $('.module-vrij').each(function () {

            var element = this;

            $(element).find('.owl-carousel').owlCarousel({
                nav: false,
                dots: false,
                loop: true,
                items: 1,
                margin: 0,
                stagePadding: 0
            });

            $(element).find('.next').click(function () {
                $(element).find('.owl-carousel').trigger('next.owl.carousel');
            });

            $(element).find('.prev').click(function () {
                $(element).find('.owl-carousel').trigger('prev.owl.carousel');
            });
        });
    }

    /////////////////////////////////////////
    // Module History Slider ////////////////
    /////////////////////////////////////////
    if ($('.module-history-slider .owl-carousel').length >= 1) {
        $('.module-history-slider').each(function () {

            var element = this;
            var max = $(this).find('.owl-carousel').attr('data-max_items_per_regel');

            $(element).find('.owl-carousel').owlCarousel({
                nav: false,
                margin: 0,
                dots: false,
                loop: false,
                items: max,
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                responsive: {
                    0: {
                        items: 1,
                        margin: 1
                    },
                    600: {
                        items: max - 1,
                        margin: 1
                    },
                    1200: {
                        items: max,
                        margin: 1
                    }
                }

            });

            $(element).find('.next').click(function () {
                $(element).find('.owl-carousel').trigger('next.owl.carousel');
            });

            $(element).find('.prev').click(function () {
                $(element).find('.owl-carousel').trigger('prev.owl.carousel');
            });

            $(element).find('.prev').hide();
            $(element).find('.owl-carousel').on('changed.owl.carousel', function (event) {
                if (event.item.index == 0) {
                    $(element).find('.prev').fadeOut();
                } else {
                    $(element).find('.prev').fadeIn();
                }

                var current = Math.ceil(event.item.count / event.page.size);
                if (event.item.index > current) {
                    $(element).find('.next').fadeOut();
                } else {
                    $(element).find('.next').fadeIn();
                }
            });
        });
    }

    /////////////////////////////////////////
    // Module Logo Slider ////////////////
    /////////////////////////////////////////
    if ($('.module-logo-slider .owl-carousel').length >= 1) {
        $('.module-logo-slider').each(function () {

            var element = this;
            var max = $(this).find('.owl-carousel').attr('data-max_items_per_regel');

            $(element).find('.owl-carousel').owlCarousel({

                dots: false,
                loop: $(element).find('.owl-carousel .item').length > 1 ? true : false,
                items: max,
                autoplay: true,
                autoplayTimeout: 4500,
                autoplayHoverPause: true,
                responsive: {
                    0: {
                        items: 3,
                        margin: 10
                    },
                    600: {
                        items: 4,
                        margin: 27
                    },
                    1200: {
                        items: 5,
                        margin: 20
                    },
                    1500: {
                        items: max,
                        margin: 20
                    }
                }

            });

            $(element).find('.next').remove();
            $(element).find('.prev').remove();

            $(element).find('.next').click(function () {
                $(element).find('.owl-carousel').trigger('next.owl.carousel');
            });

            $(element).find('.prev').click(function () {
                $(element).find('.owl-carousel').trigger('prev.owl.carousel');
            });
        });
    }

    /////////////////////////////////////////
    // Main caroucel - module-main-slider ///
    /////////////////////////////////////////
    if ($('.module-quote .owl-carousel').length >= 1) {

        $('.module-quote').each(function () {

            var element = this;

            console.log(element);

            $(element).find('.owl-carousel').owlCarousel({
                loop: $(element).find('.owl-carousel .item').length > 1 ? true : false,
                margin: 0,
                nav: false,
                dots: false,
                items: 1,
                navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"]
            });

            $(element).find('.next').click(function () {
                $(element).find('.owl-carousel').trigger('next.owl.carousel');
            });

            $(element).find('.prev').click(function () {
                $(element).find('.owl-carousel').trigger('prev.owl.carousel');
            });
        });
    }
}); // end jquery


// check if succesvol 
console.log('JS geladen!');